var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-navbar",class:[ 
        !_vm.MenuCollapsed ? 'show' : '' ,
        _vm.headerFloatMode ? 'nav-float' : '',
        _vm.layoutInternal ? 'layout-internal' : '',
        _vm.layoutFull ? 'layout-full' : '' ]},[_c('div',{staticClass:"container"},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-light"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"},nativeOn:{"click":function($event){_vm.MenuCollapsed = true}}},[_c('svg',{staticClass:"brand",attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 431.89 236.38"}},[_c('path',{staticClass:"brand-path",attrs:{"d":"M281.12,102.16V32.59H411.29L431.89.28V0H248.53V102.16ZM106.23,182h2.18v15h-2.18Zm71.1,0,31.6,7.19L240.56,182v15h-2.18V184.23L209,191l-29.44-6.76v12.69h-2.18V182ZM42.47,186.9H87v1.77H42.47v8.25H40.29V182h61.23v1.77h-59Zm72.63,8.24h55.21v1.78H112.93V182h2.17ZM0,236.38H281.12V159.05H248.53v44.74H32.59v-120l85.86,86.73,85.34-86.73V146.9h135L359,114.31H236.38V0L117.93,120.34,0,0Z"}})])]),_c('button',{staticClass:"navbar-toggler",class:[
          _vm.MenuCollapsed 
          ? 'collapsed' : ''            
        ],attrs:{"type":"button"},on:{"click":function($event){_vm.MenuCollapsed = !_vm.MenuCollapsed}}},[_c('span',{staticClass:"icon-bar top-bar"}),_c('span',{staticClass:"icon-bar middle-bar"}),_c('span',{staticClass:"icon-bar bottom-bar"})]),_c('div',{staticClass:"collapse navbar-collapse",class:_vm.MenuCollapsed ? '' : 'show',attrs:{"id":"navbarNavDropdown"}},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item active"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'about'}},nativeOn:{"click":function($event){_vm.MenuCollapsed = !_vm.MenuCollapsed}}},[_vm._v("Quem Somos")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'insulfilmJanela'}},nativeOn:{"click":function($event){_vm.MenuCollapsed = !_vm.MenuCollapsed}}},[_vm._v("Película para Janela")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'work'}},nativeOn:{"click":function($event){_vm.MenuCollapsed = !_vm.MenuCollapsed}}},[_vm._v("Nossos Trabalhos")])],1),_c('li',{staticClass:"nav-item cotacao"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'quotation'}},nativeOn:{"click":function($event){_vm.MenuCollapsed = !_vm.MenuCollapsed}}},[_vm._v("Cotação")])],1)]),(!_vm.MenuCollapsed)?_c('div',{staticClass:"container container-button"},[_c('router-link',{attrs:{"to":{ name : 'quotation' }},nativeOn:{"click":function($event){_vm.MenuCollapsed = !_vm.MenuCollapsed}}},[_c('ButtonDefault',{staticClass:"button",attrs:{"rotulo":"Faça uma Cotação!","btnStyle":"primary","btnSize":"small"}})],1)],1):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }