<template>
  <div class="photo cursor-action fade-in" :class="size">
       
    <div class="photo-img">
      <div class="photo-container">
        <img             
          :src="`${ src }`"
          :alt="`${description}`"
          @load="checkPhotoLoad($event)"
          loading="lazy"
        >          
      </div>
      <Loading v-if="!photoLoaded" />
    </div>

    <div class="photo-content">
      <div class="photo-header">
        <svg 
          class="icon icon-big"
          :class="getIconLocation.iconName"
        >
          <use :xlink:href="`/images/icon-lib.svg#${getIconLocation.iconName}`" />
        </svg>
        <div class="photo-content-title">
          {{ title }}
        </div>
      </div>

      <div class="photo-content-text">
        {{ description }}
      </div>
    </div>

    <div class="item-overlay"></div>
  </div>
</template>

<script>
import Loading from "@/components/shared/loading.vue";

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    idLocation: {
      type: Number,
      required: true,
    },
  },

  data: function () {
    return {
      photoLoaded: false,

      photoIcon: [
        {
          idLocation: 1,
          iconName: 'architecture-window-1'
        },
        {
          idLocation: 2,
          iconName: 'lift'
        },
        {
          idLocation: 3,
          iconName: 'camping-shelter'
        },
        {
          idLocation: 4,
          iconName: 'appliances-fridge'
        },
        {
          idLocation: 5,
          iconName: 'modern-architecture-buildings'
        },
        {
          idLocation: 6,
          iconName: 'dresser-double-door-1'
        },
        {
          idLocation: 7,
          iconName: 'buildings-1'
        },
        {
          idLocation: 8,
          iconName: 'show-theater'
        },
      ]
    };
  },
  components : {
    Loading
  },
  methods: {
    checkPhotoLoad: function () {      
      this.photoLoaded = true;
    },
  },
  computed: {
    getIconLocation: function () {
      const idLocation = this.idLocation;
      if (!idLocation) return '';
      return this.photoIcon.find( item => item.idLocation === idLocation);
    }

  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
.photo {
  position: relative;
  /*   display: flex;
  flex-wrap: wrap; */
  width: 100%;
  //Mudar altura para resoluções maiores
  height: 300px;
  color: #fff;
  overflow: hidden;

  .photo-img {
    background-color: rgba(30, 30, 30, 0.9);
  }

  &:hover {
    .photo-img {
      transform: scale(1.2);
      // causando lentidão no navegador
      transition: transform 9s cubic-bezier(0.1, 0.2, 0.7, 1);
    }

    .photo-content {
      // margem inferior para a legenda
      // quanto irá subir
      transform: translateY(-95%);
      //transform: translateY(-149px);
    }

    .photo-content-text {
      opacity: 1;
      display: block;
      transform: translateY(0%);

      // causando lentidão no navegador
      transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s,
        transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s;
    }

    .item-overlay {
      opacity: 0.9;
      /* visibility: visible; */
      //background-image: linear-gradient(rgba(0, 0, 0, 0.1),  rgba(0, 0, 0, 0.9));
    }
  }
}

.photo-img {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  transition: transform 1s ease, opacity 0.5s ease 0.25s;
  width: 100%;
  height: 300px;
}
//.photo-1 {
//Mudar altura para resoluções maiores
//background-image:
/* top, transparent */ /* linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.3)
      ), */ /* url("/images/img-pelicula-janela-senac.jpg"); */
//}

.photo-content {
  z-index: 10;
  //height: 51%;
  position: relative;
  align-self: flex-end;
  padding: 0 1.25rem 1.25rem;

  top: 100%;

  //exibir a parte superior
  transform: translateY(-60%);

  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);

  .icon {
    margin-bottom: 0.25rem;
  }
}

.photo-header {
  transform: translateY(0px);

  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
    transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}

.photo-content-title {
  font-size: 1.375rem;
  font-weight: 500;
  margin-bottom: 0.25rem;

  @media (max-width: $screenLarge) {
    font-size: 1.125rem;
  }
}

.photo-content-text {
  opacity: 0;
  //height: 37.21%;
  //display: none;
  /*         position: absolute;
        bottom: 0;
        left: 0;
        margin-top: 5px; */
  transform: translateY(100%);

  @media (max-width: $screenLarge) {
    font-size: $BodyText2;
  }


}

.item-overlay {
  // background-color: rgba(0,0,0,0.4);

  //background-image: linear-gradient(rgba(0, 0, 0, 0),  rgba(0, 0, 0, 0.3));

  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9));
  position: absolute;
  // visibility: hidden;
  overflow: hidden;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
  width: 100%;

  transition: all 200ms ease-out;
}

.photo.medium {
  height: 340px;
  .photo-img {
    height: 340px;
  }  
  @media (min-width: $screenLarge) {
    height: 450px;
    .photo-img {
      height: 450px;
    }
  }
}

.photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
  overflow: hidden;
}

</style>