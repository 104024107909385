<template>
  <div
    class="container-navbar"
    :class="[ 
          !MenuCollapsed ? 'show' : '' ,
          headerFloatMode ? 'nav-float' : '',
          layoutInternal ? 'layout-internal' : '',
          layoutFull ? 'layout-full' : '',
        ]"
  >
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <router-link to="/" @click.native="MenuCollapsed = true" class="navbar-brand">
          <svg
            version="1.1"
            class="brand"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 431.89 236.38"
          >
            <path
              class="brand-path"
              d="M281.12,102.16V32.59H411.29L431.89.28V0H248.53V102.16ZM106.23,182h2.18v15h-2.18Zm71.1,0,31.6,7.19L240.56,182v15h-2.18V184.23L209,191l-29.44-6.76v12.69h-2.18V182ZM42.47,186.9H87v1.77H42.47v8.25H40.29V182h61.23v1.77h-59Zm72.63,8.24h55.21v1.78H112.93V182h2.17ZM0,236.38H281.12V159.05H248.53v44.74H32.59v-120l85.86,86.73,85.34-86.73V146.9h135L359,114.31H236.38V0L117.93,120.34,0,0Z"
            />
          </svg>
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          @click="MenuCollapsed = !MenuCollapsed"
          :class="[
            MenuCollapsed 
            ? 'collapsed' : ''            
          ]"
        >
          <span class="icon-bar top-bar"></span>
          <span class="icon-bar middle-bar"></span>
          <span class="icon-bar bottom-bar"></span>
        </button>

        <div
          class="collapse navbar-collapse"
          id="navbarNavDropdown"
          :class="MenuCollapsed ? '' : 'show'"
        >
          <ul class="navbar-nav">
            <li class="nav-item active">
              <router-link class="nav-link" :to="{ name: 'about'}" @click.native="MenuCollapsed = !MenuCollapsed">Quem Somos</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'insulfilmJanela'}" @click.native="MenuCollapsed = !MenuCollapsed">Película para Janela</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'work'}" @click.native="MenuCollapsed = !MenuCollapsed">Nossos Trabalhos</router-link>
            </li>
            <li class="nav-item cotacao">
              <router-link class="nav-link" :to="{ name: 'quotation'}" @click.native="MenuCollapsed = !MenuCollapsed">Cotação</router-link>
            </li>
          </ul>

          <div class="container container-button" v-if="!MenuCollapsed">
            <router-link :to="{ name : 'quotation' }" @click.native="MenuCollapsed = !MenuCollapsed">
              <ButtonDefault
                class="button"
                rotulo="Faça uma Cotação!"
                btnStyle="primary"
                btnSize="small"
              />
            </router-link>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import ButtonDefault from "@/components/shared/buttons/button-default.vue";

export default {
  components: {
    ButtonDefault,
  },

  props: {
    layoutInternal: {
      type: Boolean,
      required: false,
    },
    layoutFull: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      MenuCollapsed: true,
      headerFloatMode: false,
    };
  },

  mounted: function () {
    window.addEventListener("scroll", this.checkHeaderForFloat);
  },

  methods: {
    checkHeaderForFloat: function () {
      let top = window.pageYOffset || document.scrollTop || 0;
      if (top > 70 && !this.headerFloatMode) this.headerFloatMode = true;
      else if (top <= 70 && this.headerFloatMode) this.headerFloatMode = false;
    },
  },

  computed: {
    checkLayoutInternal: function () {
      return this.LayoutInternal ? true : false;
    },
  },

  destroyed: function () {
    window.removeEventListener("scroll", this.checkHeaderForFloat);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.container-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 1rem 0;
  background: rgb(84, 84, 84);
  background: linear-gradient(
    0deg,
    rgba(84, 84, 84, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );

  transition: all 0.3s;

  @media (min-width: $screenLarge) {
    padding: 1.631rem 0;
  }

  &.layout-internal, &.layout-full {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 100%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &.show {
    @media (max-width: $screenSmall) {
      background: #fff;
    }
  }

  &.nav-float, &.layout-full {
    background: #fff;
  }
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $screenLarge) {
    padding: 0;
  }
}

.navbar-brand {
  display: inline-block;
  white-space: nowrap;

  &:hover .brand-path {
    opacity: 1;
  }
  .brand {
    width: 56px;
    height: 29px;

    vertical-align: middle;
    opacity: 0.75;

    @media (min-width: $screenLarge) {
      width: 60px;
      height: 32.84px;
    }
  }
  .brand-path {
    fill: #fff;
    opacity: 0.8;
    transition: all 0.3s;
  }
}
.show .navbar-brand .brand-path,
.nav-float .navbar-brand .brand-path, .layout-full .navbar-brand .brand-path { 
  opacity: 1;
}

.nav-float .navbar-brand .brand-path, .layout-full .navbar-brand .brand-path {  
  fill: $black-900;
}

@media (max-width: $screenLarge) {
  .show .navbar-brand .brand-path{
    fill: $black-900;  
  }
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

#navbarNavDropdown {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 0;
  background-color: $black-900;
  z-index: 1000;
  -webkit-transition: all 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);
  transition: all 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);
  overflow: hidden;

  @media (min-width: $screenLarge) {
    position: relative;
    top: 0;
    height: auto;
    background-color: transparent;
    overflow: initial;
  }

  &.show {
    height: 100vh;

    @media (min-width: $screenLarge) {
      height: auto;
    }
  }
}

@media (min-width: $screenLarge) {
  .navbar-expand-lg {
    flex-flow: row nowrap;

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
  }
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding: 1.25rem 2rem 0 2rem;
  margin-bottom: 0;
  list-style: none;

  @media (min-width: $screenLarge) {
    padding: 0;
    margin: 0;
  }
}

@media (min-width: $screenLarge) {
  .navbar-expand-lg .navbar-nav {
    margin-left: auto;
    flex-direction: row;
  }
}

.nav-item.cotacao {
  display: none;
  @media (min-width: $screenLarge) {
    display: block;
  }
}
.container-button {
  margin-top: 2rem;
  
  @media (min-width: $screenLarge) {
    display: none;
  }
}

.nav-item {
   @media (min-width: $screenLarge) {
    padding: 0rem 1.625rem;
   }
}

.nav-link {
  position: relative;
  display: block;
  padding: 0.875rem 0rem;
  color: #fff;
  font-weight: 500;
  opacity: 0.65;

  &::after {                
    content: '';
    position: absolute;
    display: block;
    bottom: -6px;
    left: 50%;    
    width: 0;
    border-top: 2px solid #FFF;
    padding-bottom: inherit;
    transition: width .3s ease-out, left .3s ease-out, border-color .3s ease-out;
  }

  @media (min-width: $screenLarge) {
    padding: 0rem ;
    font-size: $BodyText2;

    &:hover {
      opacity: 1;
    }    
    &:hover::after, &.router-link-active::after {
      left: 0;
      width: 100%;
    }    
  }
}

.layout-internal .nav-link {
  @media (min-width: $screenLarge) {
    opacity: 0.8;
    &.router-link-active {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.nav-float .nav-link, .layout-full .nav-link {
  &::after {  
    border-top: 2px solid $black;
  }
  @media (min-width: $screenLarge) {
    color: $black;
  }
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);

  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &:focus {
    outline: none;
  }
}

.navbar-toggler {
  margin-left: auto;
  border: none;
  background: transparent !important;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 0.8;
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

.icon-bar {
  width: 22px;
  height: 2px;
  display: block;
  margin-top: 4px;
  background-color: #fff;
  opacity: 0.8;
  transition: all 0.2s;
}

.show .icon-bar,
.nav-float .icon-bar, .layout-full .icon-bar {
  background-color: $black-900;
}

@media (min-width: $screenLarge) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
</style>