import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Quotation from '@/views/quotation.vue'
import VueGtm from "vue-gtm";

Vue.use(VueRouter)

Vue.use(VueGtm, {
  id: "GTM-KB4HV5D", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
/*   queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
    gtm_preview: "env-4",
    gtm_cookies_win: "x",
  }, */
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Aplicação de Películas e Adesivos | MF Film' }, 
    component: Home
  },
  
  /* rota continua aparecedno no analytics com origem do ads  */
  {
    path: '/index.shtml',
    name: 'homeIndexShtml',
    meta: { title: 'Aplicação de Películas e Adesivos | MF Film' }, 
    component: Home
  },
  {
    path: '/mf',
    name: 'homeMf',
    meta: { title: 'Aplicação de Películas e Adesivos | MF Film' }, 
    component: Home
  },
  {
    path: '/mf/*',
    name: 'homeMfInternas',
    meta: { title: 'Aplicação de Películas e Adesivos | MF Film' }, 
    component: Home
  },
  /* rota continua aparecedno no analytics com origem do ads  */

  {
    path: '/cotacao',
    name: 'quotation',
    meta: { title: 'Cotação para instalar Películas e Adesivos | MF Film' },
    component: Quotation
  },

  {
    path: '/sobre-nos',
    name: 'about',
    meta: { title: 'Conheça a MF Instalação de Películas e Adesivos | MF Film' },
    component: () => import(/* webpackChunkName: "about-new" */ '@/views/about-new.vue')
  },
  {
    path: '/nossos-trabalhos',
    name: 'work',
    meta: { 
      title: 'Fotos do nosso trabalho com Películas e Adesivos | MF Film',
      description: '' 
    }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/nossos-trabalhos/pelicula-adesivo/elevador',
    name: 'lift',
    meta: { title: 'Películas e Adesivos para elevadores | MF Film' }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/nossos-trabalhos/pelicula-adesivo/janela',
    name: 'window',
    meta: { title: 'Películas e Adesivos para janelas | MF Film' }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/nossos-trabalhos/pelicula/guarita',
    name: 'guardhouse',
    meta: { title: 'Películas e Adesivos para guaritas | MF Film' }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/nossos-trabalhos/pelicula-envelopamento/eletrodomesticos',
    name: 'glass',
    meta: { title: 'Películas e Adesivos para eletrodomésticos | MF Film' }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/nossos-trabalhos/pelicula-adesivo/tetos-paredes',
    name: 'roof',
    meta: { title: 'Películas e Adesivos para tetos e paredes | MF Film' }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/nossos-trabalhos/pelicula-adesivo/portas-divisorias',
    name: 'door',
    meta: { title: 'Películas e Adesivos para portas e divisórias | MF Film' }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/nossos-trabalhos/pelicula/varandas-sacadas',
    name: 'balcony',
    meta: { title: 'Películas e Adesivos para sacadas | MF Film' }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },
  {
    path: '/nossos-trabalhos/pelicula/vitrines',
    name: 'showcase',
    meta: { title: 'Películas e Adesivos para vitrines | MF Film' }, 
    component: () => import(/* webpackChunkName: "work" */ '@/views/work.vue')
  },

  {
    path: '/pelicula-intercontroll',
    name: 'filmIntercontrol',
    meta: { title: 'Instalação de Películas InterControl | MF Film' },     
    component: () => import(/* webpackChunkName: "filmIntercontrol" */ '@/views/film/film-intercontrol.vue')
  },

  {
    path: '/pelicula-3m',
    name: 'film3m',
    meta: { title: 'Instalação de Películas 3M | MF Film' },         
    component: () => import(/* webpackChunkName: "film3m" */ '@/views/film/film-3m.vue')
  },

  {
    path: '/pelicula-suntek',
    name: 'filmSunTek',
    meta: { title: 'Instalação de Películas SunTek | MF Film' },      
    component: () => import(/* webpackChunkName: "filmSunTek" */ '@/views/film/film-suntek.vue')
  },

  {
    path: '/pelicula-insulfilm-para-janela',
    name: 'insulfilmJanela',
    meta: { title: 'Instalação de Películas (insulfilm) para Janela | MF Film' },      
    component: () => import(/* webpackChunkName: "insulfilmJanela" */ '@/views/location/janela/insulfilm-janela.vue')    
  },
  {
    path: '/pelicula-insulfilm-protecao-solar-para-janela',
    name: 'insulfilmProtecaoSolarJanela',
    meta: { title: 'Instalação de insulfilm de Proteção Solar para Janela | MF Film' },      
    component: () => import(/* webpackChunkName: "insulfilmProtecaoSolarJanela" */ '@/views/location/janela/insulfilm-protecao-solar-janela.vue')    
  },
  {
    path: '/pelicula-insulfilm-seguranca-para-janela',
    name: 'insulfilmSegurancaJanela',
    meta: { title: 'Aplicação de Películas (insulfilm) de Segurança para Janela | MF Film' },      
    component: () => import(/* webpackChunkName: "insulfilmSegurancaJanela" */ '@/views/location/janela/insulfilm-seguranca-janela.vue')
  },
  {
    path: '/pelicula-insulfilm-decorativo-para-janela',
    name: 'insulfilmDecorativoJanela',
    meta: { title: 'Instalação de Películas (insulfilm) decorativo para Janela | MF Film' },      
    component: () => import(/* webpackChunkName: "insulfilmDecorativoJanela" */ '@/views/location/janela/insulfilm-decorativo-janela.vue')
  },
  {
    path: '/pelicula-insulfilm-privacidade-para-janela',
    name: 'insulfilmPrivacidadeJanela',
    meta: { title: 'Instalação de Películas (insulfilm) privacidade para Janela | MF Film' },      
    component: () => import(/* webpackChunkName: "insulfilmDecorativoJanela" */ '@/views/location/janela/insulfilm-privacidade-janela.vue')
  },

  { 
    path: '*', 
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/not-found.vue')    
  }

]

const router = new VueRouter({
  mode: 'history',

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },

  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Aplicação de Películas e Adesivos | MF Film';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});


export default router
